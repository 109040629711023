import React from "react";
import theme from "theme";
import { Theme, Link, Text, Box } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { Override, Section, StackItem, Stack } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<Helmet>
			<title>
				Team - kursin.com - modern web development
			</title>
			<meta name={"description"} content={"Launch your startup in a couple of weeks. We are creating great web or mobile applications quickly using modern NoCode tools or pure code."} />
			<meta property={"og:title"} content={"Team - kursin.com - modern web development"} />
			<meta property={"og:description"} content={"It all begins with an idea. Maybe you want to launch a business. Maybe you want to turn a hobby into something bigger. Or maybe you have a creative project to share with the world. Whatever it is, the way you tell your story online can make all the difference."} />
			<meta property={"og:image"} content={"https://images.unsplash.com/photo-1589561253898-768105ca91a8?ixlib=rb-1.2.1&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/readme/cra/favicon-32x32.ico"} type={"image/x-icon"} />
			<script async src={`https://www.googletagmanager.com/gtag/js?id=UA-176560010-1`}></script>
     		<script>
			{`
      	    window.dataLayer = window.dataLayer || [];
          	function gtag(){dataLayer.push(arguments);}
          	gtag('js', new Date());
          	gtag('config', "UA-176560010-1");
        	`}
		   	</script>
		</Helmet>
		<Components.Header />
		<Section
			sm-padding="0px 0 0px 0"
			quarkly-title="About"
			box-sizing="border-box"
			padding="50px 0px 50px 0px"
			background="--color-lightD1"
			border-color="--color-lightD2"
			border-style="solid"
			border-width="1px 0px 1px 0px"
			lg-padding="50px 0px 50px 0px"
			md-padding="50px 0px 50px 0px"
			justify-content="center"
		>
			<Override
				slot="SectionContent"
				align-items="center"
				margin="0px 32px 0px 32px"
				sm-padding="24px 0px 24px 0px"
				width="100%"
				md-margin="0px 16px 0px 16px"
				min-width="auto"
			/>
			<Text
				text-align="center"
				font="--base"
				opacity="0.6"
				letter-spacing="1px"
				margin="0px 0px 10px 0px"
				color="--dark"
				lg-margin="0px 0px 6px 0px"
				lg-text-align="center"
				quarkly-title="Title"
				text-transform="uppercase"
			>
				Who we are
			</Text>
			<Text
				font="normal 300 28px/1.2 --fontFamily-googleMontserrat"
				margin="0px 0px 0px 0px"
				letter-spacing="1px"
				color="--dark"
				text-align="center"
				width="85%"
				lg-font="--lead"
				sm-font="--base"
			>
				Team of specialists in modern NoCode tools like Bubble
				<br />
				and experienced full stack developers
			</Text>
		</Section>
		<Section
			padding="80px 0px 60px 0px"
			quarkly-title="Team"
			box-sizing="border-box"
			lg-padding="50px 0px 50px 0px"
			justify-content="center"
		>
			<Override
				slot="SectionContent"
				align-items="center"
				width="100%"
				margin="0px 32px 0px 32px"
				md-margin="0px 16px 0px 16px"
				min-width="auto"
			/>
			<Text
				text-align="center"
				font="--base"
				opacity="0.5"
				letter-spacing="1px"
				margin="0px 0px 10px 0px"
				color="--dark"
				text-transform="uppercase"
			>
				Meet Us
			</Text>
			<Text
				font="normal 300 42px/1.2 --fontFamily-googleMontserrat"
				margin="0px 0px 72px 0px"
				letter-spacing="1px"
				color="--dark"
				text-align="center"
				width="100%"
				md-margin="0px 0px 32px 0px"
				lg-margin="0px 0px 42px 0px"
				md-font="normal 300 28px/1.2 --fontFamily-googleMontserrat"
			>
				Our fantastic team
			</Text>
			<Stack
				lg-align-items="flex-start"
				lg-justify-content="center"
				quarkly-title="Cards"
				justify-content="space-between"
				md-justify-content="flex-start"
				sm-width="100%"
				flex-wrap="wrap"
				width="800px"
				height="auto"
				min-width="300px"
				md-max-width="80%"
			>
				<StackItem
					width="33.33%"
					lg-width="33.33%"
					sm-width="100%"
					sm-margin="0px 0px 0px 0px"
					lg-margin="0px 0px 0px 0px"
					quarkly-title="Card"
					md-width="50%"
					md-margin="0px 0px 0px 0px"
					sm-padding="16px 0px 16px 0px"
				>
					<Override
						slot="StackItemContent"
						flex-direction="column"
						padding="0px 0px 0px 0px"
						md-flex-direction="column"
						md-align-items="flex-start"
						md-justify-content="flex-start"
						sm-flex-direction="column"
					/>
					<Box
						width="100%"
						quarkly-title="Image"
						hover-background="--color-lightD1 url(https://uploads.quarkly.io/5fa7d45bbadc03001fb0bf8d/images/5f48da62233ee27aaeaaef04_CEO.jpg?v=2020-12-02T15:42:09.261Z) center center/110% no-repeat"
						transition="background --transitionDuration-normal --transitionTimingFunction-easeInOut 0s"
						background="#FFFFFF url(https://uploads.quarkly.io/5fa7d45bbadc03001fb0bf8d/images/5f48da62233ee27aaeaaef04_CEO.jpg?v=2020-12-02T15:42:09.261Z) center center/100% no-repeat"
						padding="0px 0px 100% 0px"
					/>
					<Box sm-margin="12px 0px 0px 0px" sm-width="100%">
						<Text
							font="normal 300 24px/1.2 --fontFamily-sans"
							margin="24px 0px 8px 0px"
							text-align="center"
							color="--dark"
							quarkly-title="Name"
							sm-margin="0px 0px 4px 0px"
							lg-margin="12px 0px 8px 0px"
							md-font="--lead"
							md-margin="8px 0px 2px 0px"
						>
							Petr
						</Text>
						<Text
							font="--base"
							margin="0px 0px 0px 0px"
							text-align="center"
							opacity="0.6"
							color="--dark"
							quarkly-title="Position"
						>
							Co-founder & NoCode Specialist
						</Text>
					</Box>
				</StackItem>
				<StackItem
					width="33.33%"
					lg-width="33.33%"
					sm-width="100%"
					sm-margin="0px 0px 0px 0px"
					lg-margin="0px 0px 0px 0px"
					quarkly-title="Card"
					md-width="50%"
					md-margin="0px 0px 0px 0px"
					sm-padding="16px 0px 16px 0px"
				>
					<Override
						slot="StackItemContent"
						flex-direction="column"
						padding="0px 0px 0px 0px"
						md-flex-direction="column"
						md-align-items="flex-start"
						md-justify-content="flex-start"
						sm-flex-direction="column"
					/>
					<Box
						width="100%"
						quarkly-title="Image"
						hover-background="--color-lightD1 url(https://uploads.quarkly.io/5fa7d45bbadc03001fb0bf8d/images/5f48dd3144f8701d405033b4_Designer.jpg?v=2020-12-02T15:42:09.259Z) center center/110% no-repeat"
						transition="background --transitionDuration-normal --transitionTimingFunction-easeInOut 0s"
						background="#FFFFFF url(https://uploads.quarkly.io/5fa7d45bbadc03001fb0bf8d/images/5f48dd3144f8701d405033b4_Designer.jpg?v=2020-12-02T15:42:09.259Z) center center/100% no-repeat"
						padding="0px 0px 100% 0px"
					/>
					<Box sm-margin="12px 0px 0px 0px" sm-width="100%">
						<Text
							font="normal 300 24px/1.2 --fontFamily-sans"
							margin="24px 0px 8px 0px"
							text-align="center"
							color="--dark"
							quarkly-title="Name"
							sm-margin="0px 0px 4px 0px"
							lg-margin="12px 0px 8px 0px"
							md-font="--lead"
							md-margin="8px 0px 2px 0px"
						>
							Olga
						</Text>
						<Text
							font="--base"
							margin="0px 0px 0px 0px"
							text-align="center"
							opacity="0.6"
							color="--dark"
							quarkly-title="Position"
						>
							Co-founder & Designer
						</Text>
					</Box>
				</StackItem>
				<StackItem
					width="33.33%"
					lg-width="33.33%"
					sm-width="100%"
					sm-margin="0px 0px 0px 0px"
					lg-margin="0px 0px 0px 0px"
					quarkly-title="Card"
					md-width="50%"
					md-margin="0px 0px 0px 0px"
					sm-padding="16px 0px 16px 0px"
				>
					<Override
						slot="StackItemContent"
						flex-direction="column"
						padding="0px 0px 0px 0px"
						md-flex-direction="column"
						md-align-items="flex-start"
						md-justify-content="flex-start"
						sm-flex-direction="column"
					/>
					<Box
						width="100%"
						quarkly-title="Image"
						hover-background="--color-lightD1 url(https://uploads.quarkly.io/5fa7d45bbadc03001fb0bf8d/images/5f48e02eb1ede92b640bcd21_Coder.jpg?v=2020-12-02T15:42:09.265Z) center center/110% no-repeat"
						transition="background --transitionDuration-normal --transitionTimingFunction-easeInOut 0s"
						background="#FFFFFF url(https://uploads.quarkly.io/5fa7d45bbadc03001fb0bf8d/images/5f48e02eb1ede92b640bcd21_Coder.jpg?v=2020-12-02T15:42:09.265Z) center center/100% no-repeat"
						padding="0px 0px 100% 0px"
					/>
					<Box sm-margin="12px 0px 0px 0px" sm-width="100%">
						<Text
							font="normal 300 24px/1.2 --fontFamily-sans"
							margin="24px 0px 8px 0px"
							text-align="center"
							color="--dark"
							quarkly-title="Name"
							sm-margin="0px 0px 4px 0px"
							lg-margin="12px 0px 8px 0px"
							md-font="--lead"
							md-margin="8px 0px 2px 0px"
						>
							Mr. Coder
						</Text>
						<Text
							font="--base"
							margin="0px 0px 0px 0px"
							text-align="center"
							opacity="0.6"
							color="--dark"
							quarkly-title="Position"
						>
							Full Stack Developer
						</Text>
					</Box>
				</StackItem>
				{"        "}
			</Stack>
		</Section>
		<Components.Upfooter />
		<Components.FooterMain>
			<Override slot="stackItem" lg-width="100%" sm-width="100%" />
			<Override slot="stackItemOverride" />
		</Components.FooterMain>
		<Link
			font={"--capture"}
			font-size={"10px"}
			position={"fixed"}
			bottom={"12px"}
			right={"12px"}
			z-index={"4"}
			border-radius={"4px"}
			padding={"5px 12px 4px"}
			background-color={"--dark"}
			opacity={"0.6"}
			hover-opacity={"1"}
			color={"--light"}
			cursor={"pointer"}
			transition={"--opacityOut"}
			quarkly-title={"Badge"}
			text-decoration-line={"initial"}
			href={"https://quarkly.io/"}
			target={"_blank"}
		>
			Made on Quarkly
		</Link>
	</Theme>;
});